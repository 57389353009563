
import Loader from "../../Components/Loader/Loader";
import "./HomeLoadingPage.scss";

export default function HomeLoadingPage() {

    return (
        <div className='HomeLoadingPage'>
            <div className='sticky-image-placeholder'>
                <Loader type="heart" message="Loading..."></Loader>
            </div>
            <div style={{textAlign: "center"}} className='homePageHello'>
                <h1 className="MuiTypography-root  MuiTypography-h3 MuiTypography-colorTextPrimary" style={{marginTop: "15px", marginBottom: "5px"}}>
                    Welcome to Kingdom K9 Training
                </h1>
                <h2 className="MuiTypography-root MuiTypography-h6 MuiTypography-colorPrimary">
                    Respected. Loyal. Loved.
                </h2>
            </div>

        </div>
    )
}