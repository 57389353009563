import { Typography, Grid, Button, Chip } from "@material-ui/core";
import { useState } from "react";
import TrainingContractForm, { Text, Email, Phone, YesNoMaybe, T_Header, Blank, YesNo, PlainText, T_Date } from "./TrainingContractForm";

export default function BoardAndTrainContract(props:{HandleSubmit: (data:{title:string, fields: Record<string,string>}) => void} ) {

    const [medNumber, setMedNumber] = useState(0);
    const [dogHasAllergies, setDogHasAllergies] = useState("");
    const [dogTakesMeds, setDogTakesMeds] = useState("");
    const [dogHasBeenTrained, setDogHasBeenTrained] = useState("");
    const [dogGrowls, setDogGrowls] = useState("");
    const [dogBites, setDogBites] = useState("");
    const [dogIsAggressive, setDogIsAggressive]  = useState("");
    const [dogAddBehavior, setDogAddBehavior] = useState("");

    return (
        <TrainingContractForm
            key="ContractForm-BoardAndTrain"
            title="Board & Train Contract"
            fields={[
                Text("Name"),
                Email("Email"),
                Phone("Primary phone"),
                Phone("Secondary phone"),
                Text("Street address"),
                Text("Address line 2", false),
                Text("City"),
                Text("State"),
                Text("Zip code"),
                Text("Dog's name"),
                Text("Dog's breed"),
                {
                    name: "Sex",
                    type: "select",
                    options: [
                        {
                            title: "Male",
                            value: "male",
                        },
                        {
                            title: "Female",
                            value: "female",
                        }
                    ],
                },
                Text("Age"),
                Text("Weight"),
                {
                    name: "Is your dog fixed?",
                    type: "select",
                    options: YesNoMaybe(),
                },
                Text("Who is authorized to pick up your dog?"),
                {
                    name: "Desired service",
                    type: "select",
                    options: [
                        {
                            title: "2 Week Basic Boarding and Training",
                            value: "2 Week Basic Boarding and Training",
                        },
                        {
                            title: "4 Week Intermediate Boarding and Training",
                            value: "4 Week Intermediate Boarding and Training",
                        },
                        {
                            title: "6 Week Advanced Boarding and Training",
                            value: "6 Week Advanced Boarding and Training",
                        },
                        {
                            title: "8 Week Boarding and Training",
                            value: "8 Week Boarding and Training",
                        },
                    ]
                },
                T_Header("Emergency Contact",2),
                {
                    name: "Emergency contact name",
                    type: "jsx",
                    jsx: (
                        <div style={{transformOrigin: "left center", transform: "translate(0,-30%) scale(0.75)", marginBottom: "-5px"}}>
                            <Typography component="div" variant="subtitle2" color='textSecondary' style={{display: "inline"}}>
                                (if you're unavailable)
                            </Typography>
                        </div>
                    )
                },
                Text("Emergency contact's name"),
                Phone("Emergency contact's phone number"),
                T_Header("VET INFORMATION",6),
                Text("Vet's name"),
                Text("Vet's Street Address"),
                Text("Vet's Address Line 2", false),
                Text("Vet's City"),
                Text("Vet's State"),
                Text("Vet's Zip Code"),
                {
                    name: "Does your dog have any allergies?",
                    type: "select",
                    options: YesNoMaybe(),
                    onChange: (value) => {
                        setDogHasAllergies(value);
                    }
                },
                dogHasAllergies === "yes" ? Text("Dog Allergies") : Blank(),
                {
                    name: "Does your dog take any medications?",
                    type: "select",
                    options: YesNo(),
                    value: dogTakesMeds,
                    onChange: (value) => {
                        if (value === "yes") {
                            if (medNumber === 0) {
                                setMedNumber((oldValue) => oldValue + 1);
                                setTimeout(() => {
                                    (document.querySelector("[tabIndex='29']") as any)?.focus();
                                }, 0);
                            }
                        } else {
                            setMedNumber(0);
                        }
                        setDogTakesMeds(value);
                    }
                },
                ...[0].map(()=>{
                    var arr:any[] = [];
                    for (var z = 0; z < medNumber; z++) {
                        var headerObj = T_Header(`Medication ${z + 1}`,3);
                        var nameObj = {
                            type: "text",
                            name: `Name ${z + 1}`,
                            objectLabel: `Med Name ${z + 1}`,
                        };
                        var doseObj = {
                            type: "text",
                            name: `Dose ${z + 1}`,
                            objectLabel: `Med Dose ${z + 1}`,
                        };
                        var timeObj = {
                            type: "text",
                            name: `Time ${z + 1}`,
                            objectLabel: `Med Time ${z + 1}`,
                        };

                        arr.push(headerObj);
                        arr.push(nameObj);
                        arr.push(doseObj);
                        arr.push(timeObj);
                    }
                    return arr;
                })[0]
                ,


                medNumber > 0 ?
                {
                    name: "Medication Incrementer",
                    type: "jsx",
                    jsx:
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Button
                                tabIndex={-1}
                                color="primary"
                                variant="contained"
                                fullWidth={true}
                                onClick={() => {
                                    setMedNumber((oldValue) => oldValue + 1)
                                }}
                            >
                                Add Med
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                tabIndex={-1}
                                color="primary"
                                variant="contained"
                                fullWidth={true}
                                onClick={() => {
                                    setMedNumber((oldValue) => { if (oldValue === 1) { setDogTakesMeds("no"); } return oldValue - 1; })
                                }}
                            >
                                Remove Med
                            </Button>
                        </Grid>
                    </Grid>

                }
                :
                {
                    name: "blank",
                    type: "jsx",
                    jsx: <div></div>
                },
                {
                    name: "VaccWarning",
                    type: "jsx",
                    jsx: (
                        <div style={{margin: "10px 0px"}}>
                            <Typography variant="body1" color='textPrimary'>
                                AT KK9T, WE REQUIRE EVERY DOG WHO ENTERS THE CENTER TO HAVE VACCINATION RECORDS.
                            </Typography>
                            <Typography variant="body1" color='textSecondary'>
                                Your dog needs to be up-to-date on the following vaccinations:
                            </Typography>
                            <Grid container style ={{margin: "10px 0px"}} justifyContent="center">
                                <Grid item xs={3}>
                                    <Chip
                                        variant="outlined"
                                        color="default"
                                        label="Rabies"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Chip
                                        variant="outlined"
                                        
                                        label="Bordatella"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Chip
                                        variant="outlined"
                                        
                                        label="Distemper"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )
                },
                {
                    name: "Does your dog obey commands?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Has your dog been trained before?",
                    type: "select",
                    options: YesNoMaybe(),
                    value: dogHasBeenTrained,
                    onChange: (value) => {
                        setDogHasBeenTrained(value);
                    },
                    nextField: dogHasBeenTrained === "yes" ? {
                        name: "How and what methods?",
                        type:"text"
                    } : dogHasBeenTrained === "unsure" ? {
                        name: "Please Explain",
                        type:"text"
                    } : undefined
                },
                {
                    name: "Is your dog house-trained?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Has your dog ever been in a dog fight?",
                    type: "select",
                    options: YesNoMaybe(),
                },
                {
                    name: "Does your dog growl, bark, or lunge at other dogs, humans, or children?",
                    type: "select",
                    options: YesNo(),
                    value: dogGrowls,
                    onChange: (value) => {
                        setDogGrowls(value);
                    },
                    nextField: dogGrowls === "yes" ? {
                        name: "Please describe the behavior",
                        type:"text"
                    } : undefined

                },
                {
                    name: "Has your dog ever bit another dog, human, or child?",
                    type: "select",
                    options: YesNoMaybe(),
                    value: dogBites,
                    onChange: (value) => {
                        setDogBites(value);
                    },
                    nextField: dogBites === "yes" ? {
                        name: "Please describe the biting behavior",
                        type:"text"
                    } : dogBites === "unsure" ? {
                        name: "Please Explain",
                        type:"text"
                    } : undefined

                },
                
                {
                    name: "Does your dog let you groom him/her or touch his/her nails?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog let you put on/take off collars?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Is your dog crated at night and when unsupervised?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog bark or growl in the crate?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog react negatively to touching/petting?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog exhibit destructive behavior when you leave the house?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Is your dog aggressive when people approach his/her food?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Is your dog aggressive when people try to take his/her toy away?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog growl when you try to move him/her off furniture?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog have accidents in the crate?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog have high prey drive?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Has your dog injured or killed small animals?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Is your dog crate trained?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog jumps fences?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog destroy bedding?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Is your dog invisible fence trained?",
                    type: "select",
                    options: YesNo(),
                },
                {
                    name: "Does your dog exhibit any aggressive behavior?",
                    type: "select",
                    options: YesNo(),
                    value: dogIsAggressive,
                    onChange: (value) => {
                        setDogIsAggressive(value);
                    },
                    nextField: dogIsAggressive === "yes" ? {
                        name: "Please explain aggressive behavior",
                        type: "text",
                    } : undefined
                },
                {
                    name: "Do you have anything to add about your dog's behaviors",
                    type: "select",
                    options: YesNo(),
                    value: dogAddBehavior,
                    onChange: (value) => {
                        setDogAddBehavior(value);
                    },
                    nextField: dogAddBehavior === "yes" ? {
                        name: "Additional Behavioral Details",
                        type: "text",
                    } : undefined
                },
                Text("What are your training expecations?"),
                Text("Brand of dog food"),
                T_Header("WAIVERS - GENERAL POLICY -",8),

                PlainText("$FULLWIDTH$9", `#1: KK9T, LLC. (“Kingdom K9 Training”) will endeavor to create as safe an environment
                as possible for the training and boarding care of the dog(s) identified above (the “Dog”),
                and will offer only sound, safe, and responsible training, care, and post-boarding training
                instructions. Owner agrees, however, that all animals may exhibit unpredictable
                behaviors and that Kingdom K9 Training will not be liable for the actions of the Dog that
                are outside of the control of Kingdom K9 Training. Owner agrees to allow random stool
                samples to ensure safety of kennel. Owner agrees that the recommendation by Kingdom
                K9 Training of any product or service is not a guarantee of performance or Owner’s
                satisfaction with that product or service.
                `.trim()),
                {
                    type: "text",
                    objectLabel: "Initial 1",
                    name:"Initial To Acknowledge",
                },

                PlainText("$FULLWIDTH$8", `
                    #2: Kingdom K9 Training agrees to indemnify and hold Owner harmless from and against
                    any claims of injury, expense, costs, loss or damages caused by the gross negligence or
                    willful misconduct of Kingdom K9 Training while the Dog is in the sole custody, care and
                    control of Kingdom K9 Training. Owner acknowledges that Kingdom K9 Training has no
                    control over the Dog when the Dog is not in the exclusive custody, care and control of
                    Kingdom K9 Training, and Owner agrees to indemnify and hold Kingdom K9 Training
                    harmless from and against any and all claims of injury, expense, costs, loss or damages
                    caused by the actions of the Dog when the Dog is not the sole custody, care and control
                    of Kingdom K9 Training.

                `.trim()),
                {
                    type: "text",
                    objectLabel: "Initial 2",
                    name:"Initial To Acknowledge",
                },

                PlainText("$FULLWIDTH$7", `
                    #3: Owner acknowledges and agrees that owning any animal, including the Dog, has
                    inherent risks, including but not limited to the risk of dog bites to Owner. Owner understands that the Dog will be working for its food.  
                    Owner understands that the Dog may lose 0-15lbs while boarding and training from running, working for its food and playing with other dogs for a lengthy period.
                `.trim()),
                {
                    type: "text",
                    objectLabel: "Initial 3",
                    name:"Initial To Acknowledge",
                },

                PlainText("$FULLWIDTH$6", `
                    #4: Owner fully understands the tools and techniques that are used to train and modify
                    the behavior of their dog and agrees to the tools and techniques which include Prong
                    Collar, Bark Collar, Remote Training Collar, Slip Lead, Pet Convincer, Muzzles, Flat
                    Buckle Collar, and or a Martingale Collar.                
                `.trim()),
                {
                    type: "text",
                    objectLabel: "Initial 4",
                    name:"Initial To Acknowledge",
                },
                {
                    type: "select",
                    name: "Have you watched the videos on our Facebook, TikTok, YouTube or Instagram page to see how we train and the tools we use?",
                    options: YesNo(),
                },

                T_Header("**PAYMENT POLICY**",4),
                PlainText("$FULLWIDTH$04",`
                    Payment Policy: In order to reserve your dog's Board & Train program, you must pay
                    50% of the fee upfront and the remainder is due three days before the dog is ready for pick up. You will receive a receipt of payment for your dog's training
                    program. We only accept payments via PayPal, Check or Venmo at this time. All Board &
                    Train programs are non-refundable.
                `.trim()),
                {
                    type: "select",
                    name: "By selecting 'Yes' you agree to the terms outlined above in the General Policy & Payment Policy",
                    options: YesNo(),
                },
                Text("Please initial that you have read these terms and Agree to this Payment & Cancellation Policy"),
                T_Date("Please date your initials", "Please date your initials"),

                T_Header("Photo/Video Release (All Clients)",3),
                PlainText("$FULLWIDTH$3",`
                    I hereby assign and grant to Kingdom K9 Training the right and permission to use, reproduce,
                    distribute, and publish the photograph(s), film(s), videotape(s), audio and video recording(s),
                    electronic representation(s) and/or sound recording(s) made of me and/or my dog(s) at any time
                    during the training and/or boarding of my dog(s) by Kingdom K9 Training, and I hereby release
                    Kingdom K9 Training from any and all liability resulting from such use, reproduction, distribution,
                    and publication.
                    I hereby authorize the reproduction, sale, distribution, copyright, exhibit, broadcast, and/or
                    electronic storage of any and all such photograph(s), film(s), videotape(s), audio and video
                    recording(s), electronic representation(s) and/or sound recording(s) without limitation at the
                    discretion of Kingdom K9 Training and I specifically waive any right to any compensation I may
                    have for any of the foregoing.
                `.trim()),
                Text("Please initial that you have read these terms and Agree to this Photo/Video release Agreement."),
                T_Date("Please date your initials", "Please date your initials 2"),

                T_Header("Pick-up/Drop-off Agreement",1),
                PlainText("$FULLWIDTH$0",`
                    Board and Train dogs will be picked up & dropped off by the client unless other arrangements
                    have been made.
                    At the mid- and pick-up sessions, we will go over all that your dog has learned and work with
                    you on how to start implementing the training into your daily life.
                    Follow-up lessons are provided to help ease the transition of your dog back into your home to
                    help you maintain the high standard for behavior we have at our facilities.
                    When bringing your dog for a Board and Train, all we need is your dog's food and any
                    medication your dog needs, including flea/tick and/or heartworm (only if they need to be given to your dog while your dog is with us.)

                `.trim()),
                T_Header("Do NOT bring any bedding, toys, water/food bowls, treats or bones!",3),
                PlainText("$FULLWIDTH$1",`
                    When dropping off your dog at our facility, you will need to stick to the exact time that we agreed upon. We are a working kennel and are busy working dogs in between appointments. Therefore, we arrange times to meet and greet you to make sure you and your dog gets onto the property safe and securely.
                    Upon arrival, your dog must be on a leash/collar setup from which they cannot slip out of when
                    going from your car to our property and during the hand-off. Your dog may be nervous, scared
                    or excited and any of those things can lead to your dog escaping the collar and running off or
                    around the property, which is a safety issue for the dog, yourself and other dogs on the property.
                    When you arrive, you will hand off the dog to a staff member. You are NOT to hug, kiss, bend
                    down or use a high-pitch voice at all. Instead, please just hand off the leash to the staff member.
                `.trim()),
                PlainText("$FULLWIDTH$23",`
                    Not following these instructions can jeopardize the safety of the staff member. The staff member will bring the dog back to the kennel and put him/her in the kennel.
                    You may leave food and medication and any further instructions with the staff member after the
                    Dog has been put away.
                    We do not allow non-employees into the actual kennel as we have numerous dogs with serious
                    issues, and it will create an unsafe environment for all of the dogs as well as staff and yourself.
                `.trim()),
                Text("Please initial that you have read these terms and Agree to this Pick-up/Drop-off Agreement."),
                T_Date("Please date your initials", "Please date your initials 3"),

                T_Header("Physical Activity Agreement:",3),
                PlainText("$FULLWIDTH$2",`
                    Your dog will be staying at a working kennel and not living in a padded room. Your dog spends a
                    good amount of time in the outside world doing lots of things around lots of natural objects, dirt,
                    grass, cement, trees, fencing, bushes, swimming, interacting with lots of other dogs, and
                    humans. It is likely that your dog is going to get scratched, bumped, or bruised. Your dog will
                    most likely get some wear and tear on him/her. Imagine when you went to summer camp for a
                    few weeks, you get a little banged up from all of the activities. Things are bound to happen even
                    though we have taken special care in designing our facility and maintaining a high standard of
                    safety and cleanliness.
                    As Owner, you acknowledge and understand that there are certain risks involved in pet training,
                    boarding, and care. You release, indemnify, and agree to hold Kingdom K9 Training harmless
                    from any and all manner of damages, claims, loss, liabilities, costs or expenses, causes of
                    actions or suits, whatsoever in law or equity, (including, without limitation, attorney’s fees and
                    related costs) arising out of or related to the services provided by Kingdom K9 Training.
                `.trim()),
                Text("Please initial that you have read these terms and Agree to this Physical Activity Agreement."),
                T_Date("Please date your initials", "Please date your initials 4"),



            ]}

            onSubmit={(data) => {props.HandleSubmit({title: "Board & Train Contract", fields: data})}}
        />
    )
}