import { Typography, Grid, Button, Chip, FormControl, Input, InputLabel } from "@material-ui/core";
import { useState } from "react";
import TrainingContractForm, { Text, Email, Phone, YesNoMaybe, T_Header, Blank, YesNo, PlainText, T_Date } from "./TrainingContractForm";

export default function ServicesAgreementContract(props:{HandleSubmit: (data:{title:string, fields: Record<string,string>}) => void} ) {
    const [clientName, setClientName] = useState(() => CheckParamsForString("clientName"));
    const [feeAmount, setFeeAmount] = useState(() => CheckParamsForNumber("fee"));
    const [sessionNumber, setSessionNumber] = useState(() => CheckParamsForNumber("sessions"));
    
    const [dogName, setDogName] = useState(() => CheckParamsForString("dogName"));
    const [dogBreed, setDogBreed] = useState(() => CheckParamsForString("dogBreed"));
    const [dogAge, setDogAge] = useState(() => CheckParamsForString("dogAge"));
    const [dogColor, setDogColor] = useState(() => CheckParamsForString("dogColor"));

    const [trainerSignature, setTrainerSignature] = useState(() => CheckParamsForString("trainerSignature"));
    const [trainerSignatureDate, setTrainerSignatureDate] = useState(() => CheckParamsForString("trainerSignatureDate"));

    const [userShouldSetName, setUserShouldSetName] = useState(() => !!!CheckParamsForString("clientName"));
    const [userShouldSetFee, setUserShouldSetFee] = useState(() => !!!CheckParamsForNumber("fee"));
    const [userShouldSetSessions, setUserShouldSetSessions] = useState(() => !!!CheckParamsForString("sessions"));

    const [userShouldSetDogName, setUserShouldSetDogName] = useState(() => !!!CheckParamsForString("dogName"));
    const [userShouldSetDogBreed, setUserShouldSetDogBreed] = useState(() => !!!CheckParamsForString("dogBreed"));
    const [userShouldSetDogAge, setUserShouldSetDogAge] = useState(() => !!!CheckParamsForString("dogAge"));
    const [userShouldSetDogColor, setUserShouldSetDogColor] = useState(() => !!!CheckParamsForString("dogColor"));

    const [userCanChangeTrainerSignature, setUserCanChangeTrainerSignature] = useState(() => !!!CheckParamsForString("trainerSignature"))
    const [userCanChangeTrainerSignatureDate, setUserCanChangeTrainerSignatureDate] = useState(() => !!!CheckParamsForString("trainerSignatureDate"))

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    function CheckParamsForString(param:string) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        return params[param];
    }
    function CheckParamsForNumber(param:string) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        var value:number|undefined = undefined;

        if (params[param]) {
           value = parseInt(params[param]);
        }

        return value
    }


    return (
        <span>
            <Typography variant="h4" color="primary" style={{textAlign: "center", marginBottom: "10px"}}>
                Services Agreement
            </Typography>
            
            {
                userShouldSetName ?
                <FormControl fullWidth={true} style={{marginBottom: "10px"}}>
                    <InputLabel htmlFor={'client_name'}>Client Name</InputLabel>
                    <Input
                        id="client_name"
                        onChange={(e) => {
                            setClientName(e.target.value);
                        }}
                    />
                </FormControl>
                : ""
            }

            {
                userShouldSetFee ? 
                <FormControl fullWidth={true} style={{marginBottom: "10px"}}>
                    <InputLabel htmlFor={'client_fee'}>Fee Amount</InputLabel>
                    <Input
                        id="client_fee"
                        type='number'
                        onChange={(e) => {
                            setFeeAmount(parseInt(e.target.value || "0")*100);
                        }}
                    />
                </FormControl>
                : ""
            }

            {
                userShouldSetSessions ?
                <FormControl fullWidth={true} style={{marginBottom: "10px"}}>
                    <InputLabel htmlFor={'client_sessions'}>Sessions Number</InputLabel>
                    <Input
                        id="client_sessions"
                        type='number'
                        onChange={(e) => {
                            setSessionNumber(parseInt(e.target.value || "0"));
                        }}
                    />
                </FormControl>
                : ""
            }


            {
                userShouldSetDogName ?
                <FormControl fullWidth={true} style={{marginBottom: "10px"}}>
                    <InputLabel htmlFor={'dog_name'}>Dog Name</InputLabel>
                    <Input
                        id="dog_name"
                        onChange={(e) => {
                            setDogName(e.target.value);
                        }}
                    />
                </FormControl>
                : ""
            }
            {
                userShouldSetDogBreed ?
                <FormControl fullWidth={true} style={{marginBottom: "10px"}}>
                    <InputLabel htmlFor={'dog_breed'}>Dog Breed</InputLabel>
                    <Input
                        id="dog_breed"
                        onChange={(e) => {
                            setDogBreed(e.target.value);
                        }}
                    />
                </FormControl>
                : ""
            }
            {
                userShouldSetDogAge ?
                <FormControl fullWidth={true} style={{marginBottom: "10px"}}>
                    <InputLabel htmlFor={'dog_age'}>Dog Age</InputLabel>
                    <Input
                        id="dog_age"
                        onChange={(e) => {
                            setDogAge(e.target.value);
                        }}
                    />
                </FormControl>
                : ""
            }
            {
                userShouldSetDogColor ?
                <FormControl fullWidth={true} style={{marginBottom: "10px"}}>
                    <InputLabel htmlFor={'dog_color'}>Dog Color</InputLabel>
                    <Input
                        id="dog_color"
                        onChange={(e) => {
                            setDogColor(e.target.value);
                        }}
                    />
                </FormControl>
                : ""
            }


            <TrainingContractForm
                key="ContractForm-Services"
                title=""
                fields={[
                    PlainText("$FULLWIDTH$233","" +
                        "This Services Agreement between " + (clientName || "(Please Fill Out Your Name Above)") +" (hereinafter referred to " +
                        "as “Client”) and Kingdom K9 Training, LLC (hereinafter referred to as “KK9T”) pertains to the following dog:"
                    .trim()),

                    {
                        type: "plainText",
                        name: "DogName",
                        value: "Name: " + (dogName || ""),
                    },
                    {
                        type: "plainText",
                        name: "Breed",
                        value: "Breed: " + (dogBreed || ""),
                    },
                    {
                        type:"plainText",
                        name: "Age",
                        inputType: "number",
                        value: "Age: " + (dogAge || ""),
                    },
                    {
                        type: "plainText",
                        name: "Color",
                        value: "Dog Color: " + (dogColor || ""),
                    },

                    PlainText("$FULLWIDTH$1",`
                        For good and valuable consideration, the parties agree as follows:
                    `.trim()),
                    T_Header("TRAINING FEES",-1),
                    PlainText("$FULLWIDTH$0","" +
                        "Client agrees to pay KK9T a nonrefundable fee in the amount of " + formatter.format((feeAmount || 0)/100)  +
                        " for a package of " + (sessionNumber || 0)  +
                        " sessions for one hour to be paid before the first session. All training sessions must be completed within 120 " +
                        "days from the date of commencement of Services Agreement or training sessions will be forfeited."
                    .trim()),
                    T_Header("SERVICE FEES",-1),
                    PlainText("$FULLWIDTH$3", "" +
                        "The KK9T trainer agrees to provide private lessons for the client and dog on a lesson-by-lesson basis," +
                        "end goal being to teach client how to train and work with dog. Private sessions will take place at the" +
                        "client’s home. Trainer will make every reasonable effort to help client achieve training and behavioral" +
                        "modification goals but makes no guarantee of dog’s performance or behavior as a result of providing" +
                        "professional animal behavior consultation. Client understands that he/she and members of the household" +
                        "must follow KK9T trainer’s instructions without modification, work with dog daily as recommended, and" +
                        "constantly reinforce training being given to dog."
                    .trim()),
                    T_Header("CANCELLATION POLICY",-1),
                    PlainText("$FULLWIDTH$4", "" +
                        "If client fails to give at least 24 hours cancellation notice, or is not present at time of scheduled" +
                        "appointment, session fees are still due. For package deal, the session will still be counted as one session." +
                        "Client is allowed one reschedule out of the five private sessions given that will not be counted as one" +
                        "session." +
                        "\r\n" +
                        "*Client understands and agrees that if they decide to stop attending class for any reason, all monies will be" +
                        "forfeited, and Client will not be refunded the cost of the class. By signing this Services Agreement, Client" +
                        "has read the above stated provisions, and agrees to accept these responsibilities and terms of this Services" +
                        "Agreement."
                    .trim()),
                    Text("Client Signature"),
                    T_Date("Please Date Signature", "Client Signature Date"),

                    {
                        type: "jsx",
                        jsx: (
                            <div style={{marginTop: "30px"}}></div>
                        ),
                        name: "divide"
                    },

                    PlainText("$FULLWIDTH$92", "Kingdom K9 Training, LLC"),
                    PlainText("$FULLWIDTH$93", " "),

                    userCanChangeTrainerSignature ?
                        Text("Trainer's Signature", false)
                        :
                        {
                            type: "plainText",
                            name: "Trainer's Signature",
                            objectLabel: "Trainer's Signature",
                            value: "Trainer's Signature: " + (trainerSignature || ""),
                        },
                    userCanChangeTrainerSignatureDate ?
                        {
                            type: "date",
                            name: "Trainer's Signature Date",
                            objectLabel: "Trainer's Signature Date",
                            onChange: (value) => {
                                setTrainerSignatureDate(value);
                            }
                        }
                        :
                        {
                            type:"plainText",
                            name: "Trainer's Signature Date",
                            objectLabel: "Trainer Signature Date",
                            value: "Trainer's Signature Date: " + (trainerSignatureDate || ""),
                            required: false
                        },


                ]}

                onSubmit={(data) => {props.HandleSubmit({title: "Services Agreement", fields: data})}}
            />
        </span>

    )
}