
export type FetchableLink = {
    production:string,
    development:string,
}
export type FetchableLinkDictionary = {
    GetInventory: FetchableLink,
    ForwardContactMessage: FetchableLink,
    SubmitPayment: FetchableLink,
    SubmitContract: FetchableLink
}

const FetchableLinks:FetchableLinkDictionary = {
    GetInventory: {
        development: "http://localhost:5001/kingdomk9training-6b57f/us-central1/GetInventory",
        production: "https://us-central1-kingdomk9training-6b57f.cloudfunctions.net/GetInventory"
    },
    ForwardContactMessage: {
        development: "http://localhost:5001/kingdomk9training-6b57f/us-central1/ForwardContactMessage",
        production: "https://us-central1-kingdomk9training-6b57f.cloudfunctions.net/ForwardContactMessage",
    },
    SubmitPayment: {
        development: "http://localhost:5001/kingdomk9training-6b57f/us-central1/SubmitPayment",
        production: "https://us-central1-kingdomk9training-6b57f.cloudfunctions.net/SubmitPayment"
    },
    SubmitContract: {
        development: "http://localhost:5001/kingdomk9training-6b57f/us-central1/GenerateContract",
        production: "https://us-central1-kingdomk9training-6b57f.cloudfunctions.net/GenerateContract",
    }
    
}

export default FetchableLinks;