import { Typography, Grid, Button, Chip } from "@material-ui/core";
import { useState } from "react";
import TrainingContractForm, { Text, Email, Phone, YesNoMaybe, T_Header, Blank, YesNo, PlainText, T_Date } from "./TrainingContractForm";

export default function BoardDaycareContract(props:{HandleSubmit: (data:{title:string, fields: Record<string,string>}) => void} ) {
    const [medNumber, setMedNumber] = useState(0);
    const [dogHasAllergies, setDogHasAllergies] = useState("");
    const [dogTakesMeds, setDogTakesMeds] = useState("");

    return (
        <TrainingContractForm
            key="ContractForm-Boarding/Daycare"
            title="Boarding/Daycare Form"
            fields={[
                Text("Name"),
                Email("Email"),
                Phone("Primary phone"),
                Phone("Secondary phone"),
                Text("Street address"),
                Text("Address line 2", false),
                Text("City"),
                Text("State"),
                Text("Zip code"),
                Text("Dog's name"),
                Text("Dog's breed"),
                {
                    name: "Sex",
                    type: "select",
                    options: [
                        {
                            title: "Male",
                            value: "male",
                        },
                        {
                            title: "Female",
                            value: "female",
                        }
                    ],
                },
                Text("Age"),
                Text("Weight"),
                {
                    name: "Is your dog fixed?",
                    type: "select",
                    options: YesNoMaybe(),
                },
                Text("Who is authorized to pick up your dog?"),
                {
                    name: "Desired service",
                    type: "select",
                    options: [
                        {
                            title: "Boarding",
                            value: "Boarding",
                        },
                        {
                            title: "Daycare",
                            value: "Daycare",
                        },
                    ]
                },
                T_Header("Emergency Contact",-1),
                {
                    name: "Emergency contact name",
                    type: "jsx",
                    jsx: (
                        <div style={{transformOrigin: "left center", transform: "translate(0,-30%) scale(0.75)", marginBottom: "-5px"}}>
                            <Typography component="div" variant="subtitle2" color='textSecondary' style={{display: "inline"}}>
                                (if you're unavailable)
                            </Typography>
                        </div>
                    )
                },
                Text("Emergency contact's name"),
                Phone("Emergency contact's phone number"),
                T_Header("VET INFORMATION",-1),
                Text("Vet's name"),
                Text("Vet's Street Address"),
                Text("Vet's Address Line 2", false),
                Text("Vet's City"),
                Text("Vet's State"),
                Text("Vet's Zip Code"),

                {
                    name: "Does your dog have any allergies?",
                    type: "select",
                    options: YesNoMaybe(),
                    onChange: (value) => {
                        setDogHasAllergies(value);
                    }
                },
                dogHasAllergies === "yes" ? Text("Dog Allergies") : Blank(),


                {
                    name: "Does your dog take any medications?",
                    type: "select",
                    options: YesNo(),
                    value: dogTakesMeds,
                    onChange: (value) => {
                        if (value === "yes") {
                            if (medNumber === 0) {
                                setMedNumber((oldValue) => oldValue + 1);
                                setTimeout(() => {
                                    (document.querySelector("[tabIndex='29']") as any)?.focus();
                                }, 0);
                            }
                        } else {
                            setMedNumber(0);
                        }
                        setDogTakesMeds(value);
                    }
                },
                ...[0].map(()=>{
                    var arr:any[] = [];
                    for (var z = 0; z < medNumber; z++) {
                        var headerObj = T_Header(`Medication ${z + 1}`,3);
                        var nameObj = {
                            type: "text",
                            name: `Name ${z + 1}`,
                            objectLabel: `Med Name ${z + 1}`,
                        };
                        var doseObj = {
                            type: "text",
                            name: `Dose ${z + 1}`,
                            objectLabel: `Med Dose ${z + 1}`,
                        };
                        var timeObj = {
                            type: "text",
                            name: `Time ${z + 1}`,
                            objectLabel: `Med Time ${z + 1}`,
                        };

                        arr.push(headerObj);
                        arr.push(nameObj);
                        arr.push(doseObj);
                        arr.push(timeObj);
                    }
                    return arr;
                })[0]
                ,


                medNumber > 0 ?
                {
                    name: "Medication Incrementer",
                    type: "jsx",
                    jsx:
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Button
                                tabIndex={-1}
                                color="primary"
                                variant="contained"
                                fullWidth={true}
                                onClick={() => {
                                    setMedNumber((oldValue) => oldValue + 1)
                                }}
                            >
                                Add Med
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                tabIndex={-1}
                                color="primary"
                                variant="contained"
                                fullWidth={true}
                                onClick={() => {
                                    setMedNumber((oldValue) => { if (oldValue === 1) { setDogTakesMeds("no"); } return oldValue - 1; })
                                }}
                            >
                                Remove Med
                            </Button>
                        </Grid>
                    </Grid>

                }
                :
                {
                    name: "blank",
                    type: "jsx",
                    jsx: <div></div>
                },
                {
                    name: "VaccWarning",
                    type: "jsx",
                    jsx: (
                        <div style={{margin: "10px 0px"}}>
                            <Typography variant="body1" color='textPrimary'>
                                AT KK9T, WE REQUIRE EVERY DOG WHO ENTERS THE CENTER TO HAVE VACCINATION RECORDS.
                            </Typography>
                            <Typography variant="body1" color='textSecondary'>
                                Your dog needs to be up-to-date on the following vaccinations:
                            </Typography>
                            <Grid container style ={{margin: "10px 0px"}} justifyContent="center">
                                <Grid item xs={3}>
                                    <Chip
                                        variant="outlined"
                                        color="default"
                                        label="Rabies"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Chip
                                        variant="outlined"
                                        
                                        label="Bordatella"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Chip
                                        variant="outlined"
                                        
                                        label="Distemper"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )
                },
                T_Header("Pick-up/Drop-off Agreement",-1),
                PlainText("$FULLWIDTH$0",`
                    Boarding/Daycare dogs will be picked up & dropped off by the client unless other arrangements have been made. \r\n
                    When bringing your dog for boarding/daycare, all we need is your dog's food and any medication your dog needs, 
                    including flea/tick and/or heartworm (only if they need to be given to your dog while your dog is with us.)
                    \r\n
                    Do NOT bring any bedding, toys, water/food bowls, treats or bones!
                    \r\n
                    When dropping off your dog at our facility, you will need to stick to the exact time that we agreed upon. 
                    We are a working kennel and are busy working dogs in between appointments. 
                    Therefore, we arrange times to meet and greet you to make sure you and your dog gets onto the property safe and securely. 
                    Upon arrival, your dog must be on a leash/collar setup from which they cannot slip out of when going from your car to our property and during the hand-off. 
                    Your dog may be nervous, scared or excited and any of those things can lead to your dog escaping the collar and running off or around the property, 
                    which is a safety issue for the dog, yourself and other dogs on the property. 
                    You may leave food and medication and any further instructions with the staff member after the Dog has been put away. 
                    We do not allow non-employees into the actual kennel as we have numerous dogs with serious issues, 
                    and it will create an unsafe environment for all of the dogs as well as staff and yourself.
                `.trim()),
                Text("Please initial that you have read these terms and Agree to this Pick-up/Drop-off Agreement."),
                T_Date("Please date your initials", "Please date your initials"),

                T_Header("Physical Activity Agreement",-1),
                PlainText("$FULLWIDTH$01",`
                    Your dog will be staying at a working kennel and not living in a padded room. 
                    Your dog spends a good amount of time in the outside world doing lots of things around lots of natural objects, dirt, grass, cement, trees, fencing, bushes, swimming, 
                    interacting with lots of other dogs, and humans. It is likely that your dog is going to get scratched, bumped, or bruised. 
                    Your dog will most likely get some wear and tear on him/her. Imagine when you went to summer camp for a few weeks, you get a little banged up from all of the activities. 
                    Things are bound to happen even though we have taken special care in designing our facility and maintaining a high standard of safety and cleanliness.
                    \r\n
                    As the Owner, you acknowledge and understand that there are certain risks involved in pet training, boarding, and care. 
                    You release, indemnify, and agree to hold Kingdom K9 Training, LLC harmless from any and all manner of damages, claims, loss, liabilities, 
                    costs or expenses, causes of actions or suits, whatsoever in law or equity, 
                    (including, without limitation, attorney’s fees and related costs) arising out of or related to the services provided by Kingdom K9 Training, LLC.

                `.trim()),
                Text("Please initial that you have read these terms and Agree to this Physical Activity Agreement."),
                T_Date("Please date your initials", "Please date your initials 2"),
            ]}

            onSubmit={(data) => {props.HandleSubmit({title: "Boarding/Daycare Form", fields: data})}}
        />
    )
}