import { useEffect,  useState } from "react";


function useScreenIsSmall() {
    const [screenIsSmall, setScreenIsSmall] = useState(window.innerWidth <= 960);

    function CheckScreenSize() {
        if (window.innerWidth <= 960) {
            setScreenIsSmall(true);
        } else {
            setScreenIsSmall(false);
        }
    }

    useEffect(() => {
        CheckScreenSize();

        window.addEventListener("resize", CheckScreenSize);

        return () => {
            window.removeEventListener("resize",CheckScreenSize);
        }
    },[]);

    return screenIsSmall;
}

export default useScreenIsSmall;