
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import "./Loader.scss";

type LoaderTypes = "heart";

export default function Loader(props:{type: LoaderTypes, message?:string, style?:CSSProperties}) {

    return (
        <div className='loader' style={props.style}>
            <div className="lds-heart"><div></div></div>
            <span style={{textAlign:"center"}}>{props.message}</span>
        </div>
    )
}