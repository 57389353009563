import FetchableLinks, { FetchableLink } from "../data/FetchLinks";

// const fonts = {
// 	Roboto: {
// 		normal: 'fonts/Roboto-Regular.ttf',
// 		bold: 'fonts/Roboto-Medium.ttf',
// 		italics: 'fonts/Roboto-Italic.ttf',
// 		bolditalics: 'fonts/Roboto-MediumItalic.ttf'
// 	}
// };

type Props = {
    data:Record<string,string>
}

/*
 *
 * Send a form to the server which will generate a pdf and emali it to Calvin
 * 
*/
export default function useTextifyContract() {
    return TextifyContract;
}

type FieldMetaData = {
    encryptedKey:string,
    decryptedKey:string,
    order:number,
    value:string,
}
function TextifyContract(Fetch:(link:FetchableLink, requestInit:RequestInit | undefined) => Promise<any>, data:{title:string, fields: Record<string,string>}) {


    console.log("Textifying contract with this data:",data);

    return new Promise((resolve, reject) => {

        Fetch(FetchableLinks.SubmitContract, {method: "post", body: JSON.stringify(data) }).then((response) => {
            resolve(response);
        }).catch((error) => {
            console.log("Error fetching!");
            console.error(error);
            reject(error);
        })
    })
}

