import { useHistory } from "react-router";

//Hooks have to be called from within a functional component and will return the desired function
//Contrasted by normal functions which do not need hooks and can be called anywhere

export function OpenNewWindow(url:string) {
    window.open(url);
}

export function RedirectTo_Hook() {
    const history = useHistory();

    function Redirect(newPage:string) {
        history.push("/" + newPage);

        document.body.scrollTop = 0;
        var html = document.querySelector("html")
        if (html != null) {
            html.scrollTop = 0;
        }
    }
    
    return Redirect;
}