import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


import MyAppBar from './Components/MyAppBar/MyAppBar';


import './App.scss';
import "./cssVariables.css";
import Footer from './Components/Footer/Footer';
//import GalleryPage from './Pages/Gallery/GalleryPage';
import useServices from './Hooks/useServices';
//import useScreenIsSmall from './Hooks/useScreenIsSmall';
import HomeLoadingPage from './Pages/HomeLoading/HomeLoadingPage';
import useDomIsReady from './Hooks/useDomIsReady';
import ContractPage from './Pages/Contract/ContractPage';

const HomePage = React.lazy(() => import('./Pages/Home/HomePage'));
const TestimonialPage = React.lazy(() => import('./Pages/Testimonials/TestimonialPage'));
const ServicesPage = React.lazy(() => import('./Pages/Services/ServicesPage'));
const AboutUsPage = React.lazy(() => import('./Pages/AboutUs/AboutUs'));
const ContactPage = React.lazy(() => import('./Pages/Contact/ContactPage'));
const TestPage = React.lazy(() => import('./Pages/TestPage/TestPage'));
const FAQPage = React.lazy(() => import('./Pages/FAQ/FAQPage'));
const LinkGeneratorPage = React.lazy(() => import('./Pages/LinkGenerator/LinkGeneratorPage'));
const CheckoutPage = React.lazy(() => import('./Pages/Checkout/Checkout'));

function App() {
  const serviceCategories = useServices();
  const loaded = useDomIsReady();

  return (
    <Router>
      <div className='OuterContaner' style={{ marginTop: "64px", padding: "0px 0px" }}>
        <MyAppBar></MyAppBar>
        <Switch>

          <Route path='/test'>
            <Suspense fallback={
              <HomeLoadingPage></HomeLoadingPage>
            }>
              <TestPage></TestPage>
            </Suspense>
          </Route>

          {/* <Route path='/checkout'>        checkout is disabled
            <Suspense fallback={
              <HomeLoadingPage></HomeLoadingPage>
            }>
              <CheckoutPage serviceCategories={serviceCategories}></CheckoutPage>
            </Suspense>
          </Route> */}

          {/* <Route path="/gallery">
            <GalleryPage></GalleryPage>
          </Route> */}

          {/* <Route path='/linkgenerator'>     link generator is disabled
            <Suspense fallback={
              <HomeLoadingPage></HomeLoadingPage>
            }>
              <LinkGeneratorPage></LinkGeneratorPage>
            </Suspense>
          </Route> */}

          <Route path='/contracts'>
            <Suspense fallback={
              <HomeLoadingPage></HomeLoadingPage>
            }>
              <ContractPage></ContractPage>
            </Suspense>
          </Route>

          <Route path='/faq'>
              <Suspense fallback={
                <HomeLoadingPage></HomeLoadingPage>
              }>
                <FAQPage></FAQPage>
              </Suspense>
          </Route>

          <Route path="/contact">
            <Suspense fallback={
              <HomeLoadingPage></HomeLoadingPage>
            }>
              <ContactPage></ContactPage>
            </Suspense>
          </Route>

          <Route path="/aboutus">
            <Suspense fallback={
              <HomeLoadingPage></HomeLoadingPage>
            }>
              <AboutUsPage></AboutUsPage>
            </Suspense>
          </Route>

          <Route path="/services">
            <Suspense fallback={
              <HomeLoadingPage></HomeLoadingPage>
            }>
              <ServicesPage serviceCategories={serviceCategories}></ServicesPage>
            </Suspense>
          </Route>

          <Route path="/testimonials">
            <Suspense fallback={
              <HomeLoadingPage></HomeLoadingPage>
            }>
              <TestimonialPage></TestimonialPage>
            </Suspense>
          </Route>

          <Route path="/">
            <Suspense fallback={
              <HomeLoadingPage></HomeLoadingPage>
            }>
              <HomePage serviceCategories={serviceCategories}></HomePage>
            </Suspense>
            
          </Route>

        </Switch>
        {
          loaded ? <Footer></Footer> : ""
        }
      </div>
    </Router>
  );
}

export default App;


/*
  old firebase.json
    "headers": [{
      "source" : "**",
      "headers" : [{
        "key" : "Cache-Control",
        "value" : "max-age=31536000"
      }]
    }]
  }

*/