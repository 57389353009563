

import { Grid, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import { useState } from "react";
import BoardAndTrainContract from "../../Components/TrainingContract/BoardAndTrainContract";
import BoardDaycareContract from "../../Components/TrainingContract/BoardDaycareContract";
import ServicesAgreementContract from "../../Components/TrainingContract/ServiceContract";
import useTextifyContract from "../../GlobalFunctions/TextifyContract";
import useFetch from "../../Hooks/useFetch";
import "./ContractPage.scss";


export default function ContractPage() {
    const TextifyContract = useTextifyContract();
    const Fetch = useFetch();
    const [selectedContract, setSelectedContract] = 
        useState<"BoardAndTrain"|"BoardingAndDaycare"|"Services"|"">(() => CheckParamsForString("ContractTypes") as "BoardAndTrain"|"BoardingAndDaycare"|"Services"|"");
    
    const [userShouldSelectContract, setUserShouldSelectContract] =  useState(true); // useState(() => !!!CheckParamsForString("ContractType"))
    
    function CheckParamsForString(param:string) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        console.log("Found: ", params[param]);

        if (!params[param]) {return "";}

        return params[param];
    }

    function HandleSubmit(data:{title:string, fields: Record<string,string>}) {
        console.log("Sending form to server", data);

        TextifyContract(Fetch, data).then( async (res:any) => {
            console.log("Server responded:",res);
            const arrayBuffer = await res.arrayBuffer();
            console.log("buffer:",arrayBuffer);
            var blob = new Blob([arrayBuffer],{type: "octet/stream"});


            if (window.prompt("Download Contract?", "Yes")) {
                console.log("Should download...");
                downloadFile(blob,"BoardAndTrainContract.pdf");
            }
        }).catch((err) => {
            console.error(err);
        })
    }
    const downloadFile = (blob:Blob, fileName:string) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    };

    return (
        <div className='ContractPage'>
            <Grid container>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={6}>
                    
                    {/* <Button onClick={RunTheTest} variant="contained" color="primary" fullWidth={true}>
                        Run the test
                    </Button> */}
                    {/* <BoardDaycareForm HandleSubmit={HandleSubmit}/> */}
                    <div style={{margin:"0px 15px", display: userShouldSelectContract ? "block" : "none"}}>
                        <div style={{width: "80%", margin: "auto"}}>
                            <Typography component="div" variant="h3">
                                Select Contract
                            </Typography>
                            <Select
                                style={{margin: "15px auto"}}
                                fullWidth={true}
                                value={selectedContract || "Please Select Contract"}
                                onChange={
                                    (e:React.ChangeEvent<{
                                        name?: string | undefined;
                                        value: unknown;
                                    }>) => {
                                        setSelectedContract(e.target.value as any);
                                    }
                                }
                            >
                                <MenuItem
                                    value="Please Select Contract"
                                    disabled={true}
                                >
                                    Please Select Contract
                                </MenuItem>
                                <MenuItem
                                    value="BoardAndTrain"
                                >
                                    Board And Train
                                </MenuItem>
                                <MenuItem
                                    value="BoardingAndDaycare"
                                >
                                    Board/Daycare
                                </MenuItem>
                                <MenuItem
                                    value="Services"
                                >
                                    Services
                                </MenuItem>
                            </Select>
                        </div>

                    </div>

                    {
                        selectedContract.split(",").indexOf("BoardingAndDaycare") !== -1 ?
                        <Paper elevation={1} style={{padding: "15px 20px", margin: "15px 0px"}}> 
                            <BoardDaycareContract HandleSubmit={HandleSubmit}/> 
                        </Paper> : ""
                    }
                    {
                        selectedContract.split(",").indexOf("BoardAndTrain") !== -1 ?
                        <Paper elevation={1} style={{padding: "15px 20px", margin: "15px 0px"}}> 
                            <BoardAndTrainContract HandleSubmit={HandleSubmit}/> 
                        </Paper> : ""
                    }
                    {
                        selectedContract.split(",").indexOf("Services") !== -1 ?
                        <Paper elevation={1} style={{padding: "15px 20px", margin: "15px 0px"}}> 
                            <ServicesAgreementContract HandleSubmit={HandleSubmit}/> 
                        </Paper> : ""
                    }

                </Grid>
                <Grid item xs={12} md={3}></Grid>
            </Grid>
        </div>
    )
}