import { useEffect, useRef, useState } from "react";
import FetchableLinks, { FetchableLink } from "../data/FetchLinks";
import useDomIsReady from "./useDomIsReady";
import useFetch from "./useFetch";

type StoredServiceCategories = {
    timestamp:number,
    ServiceCategories:ServiceCategory[]
}
export type ServiceCategory = {
    category: {
        name:string,
        id:string,
    }
    items:ServiceType[],
}
export type ServiceType = {
    id:string,
    name:string,
    description:string,
    price?:number|string,
    extraPrice?:string,
    duration?:string,
    extraDetails?:string,
    imageURL:string,
    contracts?: m_SelectionOption[],
    order?:number,
    homepage?:boolean
}
type m_SelectionOption = {
    uid:string,
    value:string,
}


export default function useServices() {
    const [services, setServices] = useState<ServiceCategory[]>([]);
    const domIsReady = useDomIsReady();
    const servicesHaveBeenFetched = useRef(false);
    const Fetch = useFetch();

    useEffect(() => {
        if (domIsReady && !servicesHaveBeenFetched.current) {
            GetServices();
            servicesHaveBeenFetched.current = true;
        }
    },[domIsReady])

    function GetServices() {

        setTimeout(() => {
            GetServicesFromLocalStorage().then((storedServiceCategory) => {
                var now = (new Date()).getTime();
                if (storedServiceCategory && now - storedServiceCategory.timestamp < LocalServiceCacheTimes) {
                    setServices(storedServiceCategory.ServiceCategories);
                } else {
                    GetServicesFromServer(Fetch).then((services) => {
                        setServices(services);
                        SaveServicesToLocalStorage(services);
                    }).catch((error) => {
                        console.log("Error getting services from server!");
                        console.error(error);
                    })
                }
            })
        }, 0);

    }
    


    return services;
}

function GetServicesFromServer(Fetch: (input: FetchableLink, init?: RequestInit | undefined) => Promise<Response>) {
    
    return new Promise<ServiceCategory[]>((resolve, reject) => {
        Fetch(FetchableLinks.GetInventory,{method: "post"}).then((response) => {
            response.json().then((data) => {
                console.log("Server responded",data);
                if (data.success) {
                    console.log("*** !!! SERVICES FROM SERVER !!! ***", data);
                    resolve(data.inventory as ServiceCategory[]);
                } else {
                    reject(data.error);
                }
            })
        }).catch((error) => {
            console.log("Error fetching!",error);
            reject(error);
        })
    });
}

const LocalServiceCacheTimes = 0;//60*1000*60*24; //one day
const serviceKey = "KingdomK9TrainingServices";

function GetServicesFromLocalStorage() {
    return new Promise<StoredServiceCategories|null>((resolve, reject) => {
        try {
            const localServices = window.localStorage.getItem(serviceKey);
    
            if (localServices) {
                var services = JSON.parse(localServices) as StoredServiceCategories;
                resolve(services);

                console.log("*** GOT SERVICES FROM LOCAL STORAGE ***");
            }
            resolve(null);
        } catch (error) {
            reject(error);
        }
    })

}
function SaveServicesToLocalStorage(serviceCategories:ServiceCategory[]) {
    const time = (new Date()).getTime();
    var StoredServiceCategories:StoredServiceCategories = {
        timestamp: time,
        ServiceCategories:serviceCategories,
    }
    window.localStorage.setItem(serviceKey,JSON.stringify(StoredServiceCategories));
}