import { useState } from "react";
import { FetchableLink } from "../data/FetchLinks";


export default function useFetch() {
    const [isLocalHost] = useState(IsLocalHost)
    
    function IsLocalHost() {
        return window.location.href.indexOf("localhost") !== -1;
    }

    function Fetch(input:FetchableLink, init?: RequestInit | undefined) {
        var url = isLocalHost ? input.development : input.production;

        console.log("Fetching from ", isLocalHost ? "Development" : "Production");

        return fetch(url,init);
    }


    return Fetch;
}