import { AppBar, Button, makeStyles, Menu, PropTypes, Toolbar, Typography } from "@material-ui/core";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useRef, useState } from "react";
import { useHistory, matchPath } from 'react-router-dom'

import "./MyAppBar.scss";
import useScreenIsSmall from "../../Hooks/useScreenIsSmall";
import data from "../../data/data";

import { RedirectTo_Hook } from "../../Utility/RedirectUtility";

const MuiMenu = React.forwardRef((props:any,ref:any) => {
    return <Menu ref={ref} {...props} />;
})

type MenuLink = {
    title:string,
    pageName:string,
    onlyRenderLoggedIn:boolean
}
    
const MenuLinks:MenuLink[] = [
    {
        title: "home",
        pageName: "",
        onlyRenderLoggedIn:false,
    },
    {
        title: "about us",
        pageName:"aboutus",
        onlyRenderLoggedIn:false,
    },
    {
        title: "services",
        pageName:"services",
        onlyRenderLoggedIn:false,
    },
    {
        title: "testimonials",
        pageName:"testimonials",
        onlyRenderLoggedIn:false,
    },
    {
        title: "contact us",
        pageName:"contact",
        onlyRenderLoggedIn:false,
    },
    {
        title: "faq",
        pageName:"faq",
        onlyRenderLoggedIn:false,
    }
    // {    checkout is disabled
    //     title: "checkout",
    //     pageName:"checkout",
    //     onlyRenderLoggedIn: false,
    // }
];

const useStyles = makeStyles((theme: { spacing: (arg0: number) => any; }) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

export default function MyAppBar() {
    const IsSmallScreen = useScreenIsSmall();
    const [anchorElement, setAnchorElement] = useState<HTMLDivElement|null>(null);
    const appBarRef = useRef<HTMLDivElement|null>(null);
    const classes = useStyles();
    

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    var style:any = {
        height: "64px",
        transition: "all 0.25s",
    };
    var styleToolbar:any = {
        transition: "all 0.25s",
        top: "0px",
        position: "relative",
    }
    var styleIcon:any = {
        transition: "all 0.25s",
        fontSize: "38px"
    }

    if (trigger) {
        style.height = "32px";
        styleToolbar.top = "-16px";
        styleIcon.fontSize = "20px";
    }

    function handleClick(e:any) {
        setAnchorElement(e.currentTarget);
    }
    function handleClose() {
        setAnchorElement(null);
    }

    const redirect = RedirectTo_Hook();
    function RouteDirect(pageName:string) {
        redirect(pageName);
    }

    return (
            <AppBar ref={appBarRef} style={style} className={"MyHeader" + (trigger ? " triggered" : "")}>
                    <Toolbar style={styleToolbar}>
                        
                        <Typography className={classes.title + " logo" }>
                            <span style={{color: "white"}}>
                                <span    className='logo'
                                        onClick={() => { RouteDirect("") }}
                                        style={
                                            {
                                                cursor: "pointer", 
                                                width: trigger ? "25px" : "60px", 
                                                height: trigger ? "25px" : "60px", 
                                                position: "relative", 
                                                top: "5px",
                                                backgroundImage: `url('${data.logo_image}')`,
                                            }
                                }>
                                </span>
                                <span style={{position: "relative", top: trigger ? "-1px" : "-15px", left: "15px", transition: "all 0.25s linear"}}>
                                    {data.business_name}
                                </span>

                            </span>
                        </Typography>

                        <span style={{display: IsSmallScreen ? "none" : ""}}>
                            {
                                MenuLinks.map((link) => {
                                    if (link.onlyRenderLoggedIn) { return ""; }
                                    return (
                                        <MenuButton 
                                            key={link.title}
                                            Path={link.pageName}
                                            Name={link.title} 
                                            onClick={() => { RouteDirect(link.pageName) }}
                                            forceText={false}
                                        ></MenuButton>
                                    )
                                })
                            }
                        </span>

                        {
                            IsSmallScreen ? 
                            <span>
                                <Button aria-label="Open Menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                    <MenuIcon></MenuIcon>
                                </Button>
                                <MuiMenu
                                    id="simple-menu"
                                    anchorEl={anchorElement}
                                    keepMounted
                                    open={anchorElement != null}
                                    onClose={handleClose}

                                    
                                    >
                                        {
                                            MenuLinks.map((link, index) => {
                                                if (link.onlyRenderLoggedIn) { return ""; }
                                                return (
                                                    <MenuButton
                                                        key={index}
                                                        fullWidth={true} 
                                                        Path="" 
                                                        Name={link.title} 
                                                        onClick={() => { handleClose(); RouteDirect(link.pageName)}} 
                                                        color="inherit"
                                                        style={{textAlign: "left", width: "80vw", borderBottom: "0px", color: "black"}}
                                                        forceText={true}
                                                    ></MenuButton>
                                                )
                                            })
                                        }
                                </MuiMenu>
                            </span>
                            :
                            ""
                        }

                    </Toolbar>
            </AppBar>
    )

}

type MenuButtonProps = {
    onClick: ()=>void,
    style?: any,
    color?: PropTypes.Color,
    Name:string,
    Path:string,
    fullWidth?:boolean,
    forceText:boolean,
}
const MenuButton = React.forwardRef((props:MenuButtonProps, ref:any) => {
    var [isHighlighted, setIsHighlighted] = useState(false);
    var history = useHistory();

    function PathMatches(pageName:string) {

        var matches:any = matchPath(window.location.pathname, {
            path: "/" + pageName
        });

        if (pageName === "") {
            if (window.location.pathname === "" || window.location.pathname === "/") {
                matches = true;
            } else {
                matches = false;
            }
        }

        console.log("Matched?",pageName,window.location.pathname,  matches);
        return !!matches;
    }


    useEffect(() => {
        setIsHighlighted(PathMatches(props.Path));

        return history.listen(() => {
            setIsHighlighted(PathMatches(props.Path));
        });
    },[props.Path,history]);

    return (
        <Button 
            ref={ref}
            variant={isHighlighted && !props.forceText ? "contained" : "text"} onClick={props.onClick} fullWidth={props.fullWidth}
            
            style={{ 
                margin: "0px 6px", 
                paddingBottom: 0, 
                borderBottomLeftRadius: 0, 
                borderBottomRightRadius: 0, 
                borderBottom: isHighlighted ? "2px solid" : "none",
                ...props.style,
            }} 
            color={isHighlighted ? "primary" : "secondary"}
        >
            {props.Name}
        </Button>
    );
});