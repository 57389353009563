
var data = {
    business_name: "Kingdom K9 Training",
    logo_image: "https://i.ibb.co/TLkCDSK/logo60.jpg",
    phone: "(843) 621-0808",
    city: "Darling",
    state: "SC",
    address: [
        "142 Freeman St",
        "Darlington, SC",
        "29532"
    ],
    email: "kingdomk9training@gmail.com",
    hours: [
        { Days: "Monday-Friday", Hours:  "7AM-6PM" },
        { Days: "Saturday-Sunday", Hours: "Closed\r\nPick up/Drop Off\r\n12PM-2PM" },
    ],
    POBox: "P.O. Box 957"
};

export default data;