import { FormControl, InputLabel, Input, FormHelperText, Checkbox, Select, MenuItem, Button, FormControlLabel, Typography, Grid } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";

import "./TrainingContractForm.scss";

import { v4 as uuidv4, v4 } from "uuid";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import useScreenIsSmall from "../../Hooks/useScreenIsSmall";
import { Controller, useForm } from "react-hook-form";
function GetGUID() {
  return "a" + v4().replaceAll("-", "");
}

export type TrainingContract = {
    title:string,
    fields: TrainingContractField[],
}
export type TrainingContractField = {
    name:string,
    objectLabel?:string,
    type: TrainingContractFieldType,
    options?: {title:string,value:string}[],
    jsx?: ReactElement<any,any>,
    value?:string,
    onChange?: (value:string) => void,
    key?:string|number,
    nextField?:TrainingContractField,
    required?:boolean,
    tabIndex?:number,
    headerNumber?:number,
    inputType?:string
}

export type TrainingContractFieldType =  "text" | "checkbox" | "select" | "phone" | "email" | "jsx" | "header" | "plainText" | "date";

export function YesNoMaybe() {
    return [
        {
            title: "Yes", value: "yes"
        },
        {
            title: "No", value: "no"
        },
        {
            title: "Unsure", value: "unsure"
        },
    ]
}
export function YesNo() {
    return [
        {
            title: "Yes", value: "yes"
        },
        {
            title: "No", value: "no"
        }
    ]
}
export function T_Header(name:string, numberOfElementsUnderThisHeader:number) : TrainingContractField {
    return {
        name:name,
        type:"header",
        required: false,
        headerNumber:numberOfElementsUnderThisHeader,
    }
}
export function T_Date(name:string, objectLabel:string) : TrainingContractField{
    return {
        name: name,
        type: "date",
        objectLabel: objectLabel
    }
}
export function Text(name:string, required:boolean = true, value?:string) : TrainingContractField{
    return {
        name: name,
        type: "text",
        required: required,
        value: value,
    }
}
export function PlainText(name:string, value:string) : TrainingContractField{
    return {
        name: name,
        type: "plainText",
        required: false,
        value: value,
    }
}
export function Phone(name:string) : {name:string, type: TrainingContractFieldType}{
    return {
        name: name,
        type: "phone",
    }
}
export function Email(name:string) : {name:string, type: TrainingContractFieldType}{
    return {
        name: name,
        type: "email",
    }
}
export function Blank() : {name:string, type: TrainingContractFieldType, jsx: ReactElement<any,any>}{
    return {
        name: "blank",
        type: "jsx",
        jsx: <div style={{display: "none"}}></div>
    }
}

export default function TrainingContractForm(props:TrainingContract&{onSubmit:(data:Record<string,string>) => void}) {
    const { handleSubmit, control, getValues, setValue } = useForm();
    const [mailError, setMailError] = useState("");
    const [mailSuccess, setMailSuccess] = useState("");
    const formRef = useRef<HTMLFormElement|null>(null);

    //const [fieldValues, fieldReducer] = useReducer


    function GenerateTextInput(props: {
        objectLabel: string;
        value?:string;
        label: string;
        helper: string;
        required?: boolean;
        multiline?: boolean;
        onChange?: (value:string) => void;
        inputKey?:string|number;
        tabIndex?:number;
        inputType?:string
    }) {
        const g1 = GetGUID();
        const g2 = GetGUID();

        return (
            <Controller
            key={props.inputKey}
            name={ btoa(props.objectLabel) }
            control={control}
            defaultValue=""
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormControl data-value={value} data-objectlabel={btoa(props.objectLabel)} key={props.inputKey} fullWidth={true} error={!!error}>
                        <InputLabel htmlFor={g1}>{props.label}</InputLabel>
                        <Input
                            inputProps={{
                                "tabIndex": props.tabIndex
                            }}
                            type={props.inputType}
                            key={props.inputKey}
                            disabled={!!mailError || !!mailSuccess}
                            rows={2}
                            multiline={props.multiline}
                            onChange={ (e) => { 
                                    onChange(e);
                                    if (props.onChange) {
                                        props.onChange(e.target.value as string);
                                    }
                                }
                            }
                            id={g1}
                            aria-describedby={g2}
                            required={props.required}
                            value={props.value !== undefined ? props.value : value}
                        />
                        <FormHelperText
                            style={{ color: error ? "" : "transparent" }}
                            id={g2}
                        >
                            {" "}
                            {error ? props.helper : "-"}
                        </FormHelperText>
                    </FormControl>
                );
                }}
            />
        );
    }
    function GenerateSelectInput(props: {
        objectLabel: string;
        label: string;
        helper: string;
        required?: boolean;
        options:{title:string,value:string}[];
        value?:string,
        onChange?: (value:string) => void;
        tabIndex?:number
    }) {
       // const [selectValue, setSelectValue] = useState("");
        const g1 = GetGUID();
        const g2 = GetGUID();

        useEffect(() => {
            if (props.value && props.onChange) {
                props.onChange(props.value);
            }
        },[])

        return (
            <span>
                <Controller
                    
                    name={ btoa(props.objectLabel) }
                    control={control}
                    defaultValue=""
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                            <FormControl data-value={value} data-objectlabel={btoa(props.objectLabel)} fullWidth={true} error={!!error}>
                                <InputLabel htmlFor={g1}>{props.label}</InputLabel>
                                <Select
                                    inputProps={{
                                        "tabIndex": props.tabIndex
                                    }}
                                    value={props.value === undefined ? value : props.value}
                                    disabled={!!mailError || !!mailSuccess}
                                    onChange={ (e) => { 
                                            //setSelectValue(e.target.value as string); 
                                            onChange(e);
                                            if (props.onChange) {
                                                props.onChange(e.target.value as string);
                                            }
                                        }
                                    }
                                    id={g1}
                                    aria-describedby={g2}
                                    required={props.required}
                                >
                                    {
                                        props.options.map((option, index) => {
                                            return (
                                                <MenuItem key={index} value={option.value}>{option.title}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <FormHelperText
                                    style={{ color: error ? "" : "transparent" }}
                                    id={g2}
                                >
                                    {" "}
                                    {error ? props.helper : "-"}
                                </FormHelperText>
                            </FormControl>
                        );
                    }}
                />
            </span>

        );
    }
    function GeneratePhoneInput(props: {
        objectLabel: string;
        label: string;
        helper: string;
        required?: boolean;
        options:{title:string,value:string}[];
        tabIndex?:number
    }) {
        const isSmallScreen = useScreenIsSmall();
        const g1 = GetGUID();
        const g2 = GetGUID();
    
        const [valueTrack, setValueTrack] = useState("");
        const [focused, setFocused] = useState(() => (valueTrack !== "" ? true: false));
        const phoneInputRef = useRef<any>(null);
    
        const startStyle = {
          transform: `translate(50%,${isSmallScreen ? "87%" : "82.5%"}) scale(1)`,
        };
        const focusedStyle = {
          transform: "translate(0,-50%) scale(0.8)",
        };

        useEffect(() => {
            if (phoneInputRef.current === null) {return;}

            console.log("Phone ref:",phoneInputRef.current);
            if (phoneInputRef.current.inputRef.value.length > 2) {
                setFocused(true);
            }
        })
    
        return (
          <Controller
            name={ btoa(props.objectLabel) }
            control={control}
            defaultValue=""
            rules={{required: "Please provide a valid call-back number",  minLength: 17, maxLength: 17 }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <FormControl
                  data-value={value} data-objectlabel={btoa(props.objectLabel)} 
                  fullWidth={true}
                  error={!!error}
                  style={{ marginTop: "15px" }}
                >
                  <InputLabel
                    htmlFor={g1}
                    focused={focused}
                    style={{
                      transition: "transform 0.15s ease-in-out",
                      transform:
                        focused || valueTrack.length > 3
                          ? focusedStyle.transform
                          : startStyle.transform,
                    }}
                  >
                    Phone
                  </InputLabel>
                  <MuiPhoneNumber
                    id={g1}
                    value={value}
                    disabled={!!mailError || !!mailSuccess}
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    error={!!error}
                    onChange={(value: any) => {
                      setValueTrack(value);
                      onChange(value);
                    }}
                    defaultCountry={"us"}
                    onlyCountries={["us"]}
                    disableDropdown={true}
                    countryCodeEditable={false}
                    required={props.required}
                    inputProps={{
                        "aria-describedby": g2,
                        "tabIndex": props.tabIndex
                    }}
                    ref={phoneInputRef}
                  />
                    <Input
                        tabIndex={-1}
                        value={value}
                        disabled={!!mailError || !!mailSuccess}
                        required={props.required}

                        style={{pointerEvents: "none", position: "absolute", left: 0, top: 0}}
                        fullWidth={true}

                        onFocus={() => {
                            console.log("MuiPhone Input?", phoneInputRef.current.inputRef);
                            phoneInputRef.current.inputRef.focus();
                        }}
                    />
                  <FormHelperText id={g2}>
                    {error ? "Please Provide A Valid Call-Back Number" : " "}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
        );
    }
    function GenerateEmailInput(props: {
        tabIndex?:number,
        objectLabel:string,
    }) {
        const g1 = GetGUID();
        const g2 = GetGUID();
    
        return (
          <Controller
            name={ btoa(props.objectLabel) }
            control={control}
            defaultValue=""
            rules={{
              required: "Email Required",
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <FormControl
                  data-value={value} data-objectlabel={btoa(props.objectLabel)} 
                  disabled={!!mailError || !!mailSuccess}
                  fullWidth={true}
                  error={!!error}
                >
                  <InputLabel htmlFor={g1}>{"Email"}</InputLabel>
                  <Input 
                        tabIndex={props.tabIndex}
                        value={value} 
                        onChange={onChange} 
                        id={g1} 
                        aria-describedby={g2} 
                    />
                  <FormHelperText id={g2}>
                    {" "}
                    {error ? "Please provide an email" : " "}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
        );
    }
    function GenerateHeaderInput(props: {
        objectLabel: string;
        value?:string;
        inputKey?:string|number;
        headerNumber:number
    }) {

        return (
            <Controller
            key={props.inputKey}
            name={ btoa(props.objectLabel) }
            control={control}
            defaultValue={"$HEADER$" + props.headerNumber}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormControl 
                            data-value={value} data-objectlabel={btoa(props.objectLabel)} 
                            key={props.inputKey} 
                            fullWidth={true}
                            error={!!error}
                        >

                        <div
                            key={props.inputKey}
                            style={{
                                color: "red",
                                fontSize: "1.85em"
                            }}
                        >
                            {props.objectLabel}
                        </div>
                    </FormControl>
                );
                }}
            />
        );
    }
    function GeneratePlainTextInput(props: {
        objectLabel: string;
        value?:string;
        inputKey?:string|number;
    }) {

        useEffect(() => {
            setValue(btoa(props.objectLabel),props.value);
        },[props.value])

        return (
            <Controller
            key={props.inputKey}
            name={ btoa(props.objectLabel) }
            control={control}
            defaultValue={props.value}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormControl 
                        data-objectlabel={btoa(props.objectLabel)} 
                        key={props.inputKey} 
                        fullWidth={true} 
                        error={!!error}
                    >
                        <div
                            style={{marginBottom: "2em"}}
                            key={props.inputKey}
                        >
                            {props.value}
                        </div>
                    </FormControl>
                );
                }}
            />
        );
    }
    function GenerateDateInput(props: {
        inputKey?:string|number,
        label:string,
        objectLabel:string,
        tabIndex?:number,
        required?:boolean
    }) {
        const [dayValue, setDayValue] = useState("");
        const [monthValue, setMonthValue] = useState("");
        const [yearValue, setYearValue] = useState(new Date().getFullYear().toString());
        const [name, setName] = useState(btoa(props.objectLabel))

        const isSmallScreen = useScreenIsSmall();

        const days:number[] = [];
        for (var i = 1; i < 32; i++) {
            days.push(i);
        }
        
        return (
            <Grid
                container 
                style={{alignItems: "center"}}
            >
                <Grid item xs={12} md={5} style={{ fontSize: "1.1em", textAlign: isSmallScreen ? "center" : "left"}}>
                    {props.label}
                </Grid>
                <Grid item xs={12} md={7} style={{textAlign: isSmallScreen ? "center" : "right"}}>

                    <Controller
                    name={ btoa(props.objectLabel) }
                    control={control}
                    defaultValue={"//" + (new Date()).getFullYear()}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                            <span
                                data-value={value} data-objectlabel={btoa(props.objectLabel)} 
                            >
                                <Select
                                    required={props.required}
                                    style={{minWidth: "70px"}}
                                    value={value.split("/")[0]}
                                    onChange={(e) => {
                                        var monthValue = e.target.value as string;
                                        setMonthValue(monthValue);
                                        
                                        var wholeValue = value;
                                        var split = wholeValue.split("/");
                                        split[0] = monthValue;
                                        setValue(name, split.join("/"));
                                    }}
                                >
                                {
                                    ["January","February","March","April","May","June","July","August","September", "October","November","December"].map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option}>{option}</MenuItem>
                                        )
                                    })
                                }
                                </Select>

                                <Select
                                    required={props.required}
                                    style={{minWidth: "70px", textAlign: "center"}}
                                    value={value.split("/")[1]}
                                    onChange={(e) => {
                                        var dayValue = e.target.value as string;
                                        setDayValue(dayValue);
                                        
                                        var wholeValue = value;
                                        var split = wholeValue.split("/");
                                        split[1] = dayValue;
                                        setValue(name, split.join("/"));
                                    }}
                                >
                                {
                                    days.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option}>{option}</MenuItem>
                                        )
                                    })
                                }
                                </Select>

                                <Select
                                    required={props.required}
                                    style={{minWidth: "70px", textAlign: "center"}}
                                    value={value.split("/")[2]}
                                >
                                {
                                    <MenuItem value={yearValue}>{yearValue}</MenuItem>
                                }
                                </Select>
                            </span>
                        );
                        }}
                    />


                </Grid>
            </Grid>
        )
    }

    function FieldToInputFunction(field:TrainingContractField, key:string|number) {
        var AnyInput:any = GenerateTextInput;
        switch (field.type) {
            case "select":
                AnyInput = GenerateSelectInput;
            break;
            case "phone":
                AnyInput = GeneratePhoneInput;
            break;
            case "email":
                AnyInput = GenerateEmailInput;
            break;
            case "jsx":
                if (!field.jsx) {return "";}
                
                return () => (
                    <span key={key}>
                        {field.jsx}
                    </span>
                )
            case "header":
                AnyInput = GenerateHeaderInput;
            break;
            case "plainText":
                AnyInput = GeneratePlainTextInput;
            break;
            case "date":
                AnyInput = GenerateDateInput;
            break;
        }

        return AnyInput;
    }

    var globalSuffix = "";
    function GetAnyInputData(field:TrainingContractField, suffix:string = "", depth:number = 0) {
        var AnyInputArray:any[] = [];

        for (var i = 0; i < 1; i++) {

            if (depth === 0) {
                globalSuffix = (i + 1).toString();
            }

            var AnyInput = FieldToInputFunction(field, i);

            AnyInputArray.push({fieldData: field, AnyInput: AnyInput, suffix: globalSuffix !== "1" ? globalSuffix : ""});
            if (field.nextField) {
                AnyInputArray.push(...GetAnyInputData(field.nextField,  globalSuffix, depth+1));
            }
        }

        return AnyInputArray;
    }

    function GetSingleFieldAnyInput(field:TrainingContractField,tabIndex:number) {


        var AnyInputArray:any[] = GetAnyInputData(field);
        


        return (
            <span data-test={tabIndex} key={tabIndex} >
                {
                    AnyInputArray.map((AnyInput,index) => {
                        var suffix = " " + AnyInput.suffix;

                        var fieldData = AnyInput.fieldData as TrainingContractField;
                        var TheInputComponent = AnyInput.AnyInput;

                        if (!fieldData) {return;}

                        var finalObjectLabel = (fieldData.objectLabel ? fieldData.objectLabel : (fieldData.name + suffix));

                        return (
                            <span key={index} style={{position: "relative"}}>
                                <TheInputComponent 
                                
                                    objectLabel={finalObjectLabel} 
                                    label={fieldData.name + suffix} 
                                    helper="This field is required!" 
                                    required={fieldData.required === undefined ? true : fieldData.required}
                                    options={fieldData.options}
                                    onChange={fieldData.onChange}
                                    value={fieldData.value}
                                    inputKey={fieldData.key + suffix}
                                    key={index}
                                    tabIndex={fieldData.tabIndex !== undefined ? fieldData.tabIndex : tabIndex}
                                    headerNumber={fieldData.headerNumber}
                                    inputType={field.inputType}
                                />
                            </span>

                        )
                    })
                }

            </span>
        )
    }

    function HandleSubmit(e?:React.FormEvent<HTMLFormElement>) {
        if (!formRef.current) { return; }
        e?.preventDefault();

        var dataProviders = formRef.current.querySelectorAll("[data-objectlabel]");

        var data:Record<string,string> = {};
        var allFormValues = getValues();
        for (var i = 0 ; i < dataProviders.length; i++) {
            var encryptedKey = dataProviders[i].getAttribute("data-objectlabel");
            if (encryptedKey) {
                var decryptedKey = atob(encryptedKey).trim();
                data[decryptedKey] = allFormValues[encryptedKey] as string;
            }
        }

        props.onSubmit(data);
    }

    function TEST() {
        if (!formRef.current) { return; }
        var dataProviders = formRef.current.querySelectorAll("[data-objectlabel]");

        var data:Record<string,string> = {};
        var allFormValues = getValues();
        for (var i = 0 ; i < dataProviders.length; i++) {
            var encryptedKey = dataProviders[i].getAttribute("data-objectlabel");
            if (encryptedKey) {
                var decryptedKey = atob(encryptedKey).trim();
                data[decryptedKey] = allFormValues[encryptedKey] as string;

                console.log(i,decryptedKey, allFormValues[encryptedKey]);
            }

        }
        props.onSubmit(data);
    }

    return (
        <form ref={formRef} onSubmit={HandleSubmit}>
            {/* <Button onClick={TEST} variant='contained' color='primary'>
                Run Test
            </Button> */}
            <Typography component="div" variant="h4">
                {props.title}
            </Typography>
                {
                    props.fields.map((field,index) => {

                        return (
                            <span key={index}>
                                {GetSingleFieldAnyInput(field,index)}
                            </span>
                        )

                    })
                }

            <Button type='submit' tabIndex={props.fields.length} fullWidth={true} variant="contained" color='primary' style={{marginTop: "15px"}}>
                Submit
            </Button>
        </form>

    )
}