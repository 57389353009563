import { Container, Tooltip, Typography, Zoom } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import data from "../../data/data";
import theme from "../../themes/mainTheme";
import SocialLinks from "../SocialLinks/SocialLinks";


import "./Footer.scss";

export default function Footer() {

    return (
        <Container className='SiteFooter' style={{margin: "0", maxWidth: "unset"}}>
                <Typography 
                    className=""
                    variant="button" 
                    color="secondary" 
                    style={{position: "relative", textAlign: "center", margin: "auto", display: "block", fontSize: "2rem"}}
                    >
                    Kingdom K9 Training
                    <div className='MidShortLine' style={{top: "95%", backgroundColor: theme.palette.secondary.main}}>
                    </div>
                </Typography>
                
                <Typography component="div" variant="h6" color="secondary" style={{textAlign: "center", marginTop: "0px"}}>
                    Respected <FiberManualRecordIcon fontSize="small" style={{transform: "translate(0,18.5%)"}}/> Loyal <FiberManualRecordIcon fontSize="small" style={{transform: "translate(0,18.5%)"}}/> Loved
                </Typography>

                <Typography variant="body1" style={{color: theme.palette.primary.contrastText, textAlign: "center", marginTop: "12px"}}>
                    <MailToSupport></MailToSupport>
                </Typography>

                <div style={{textAlign:"center", marginTop: "15px"}}>
                    <SocialLinks fontSize="large"></SocialLinks>
                </div>

                <div style={{textAlign: "center", marginTop: "30px"}}>
                    <Typography style={{opacity: 1, color: theme.palette.primary.contrastText}} variant="caption">
                        {/* COPYRIGHT © 2021, */}Kingdom K9 Training LLC, ALL RIGHTS RESERVED 
                    </Typography>
                </div>

        </Container>
    )
}

function MailToSupport() {

    function OpenEmailClient() {
        window.open(`mailto:${data.email}?Subject=Client Inquiry`)
    }
    function OpenCallingClient() {
        window.open(`tel:+${data.phone.replace(/[() -]/g,"")}`);
    }

    return (
        <span>

            <Tooltip title="Click To Email" arrow={true} TransitionComponent={Zoom}>
                <span onClick={OpenEmailClient} className='mailTo'>
                    {data.email} <br />
                </span>
            </Tooltip>
            <Tooltip title="Click To Call" arrow={true} TransitionComponent={Zoom}>
                <span onClick={OpenCallingClient} className='mailTo'>
                    {data.phone}
                </span>
            </Tooltip>
        </span>
    )
}