import { useEffect, useState } from "react";


export default function useDomIsReady() {
    const [domIsReady, setDomIsReady] = useState(false);


    useEffect(() => {
        if (document.readyState === "complete") {
            setDomIsReady(true);
            return;
        }

        window.addEventListener("load", OnDomContentLoaded);

        return () => {
            window.removeEventListener("load",OnDomContentLoaded);
        }
    },[])

    function OnDomContentLoaded() {
        setTimeout(() => {
            setDomIsReady(true);
        }, 0);

        window.removeEventListener("load",OnDomContentLoaded);
    }

    return domIsReady;
}